import React, { useState } from 'react';
import './App.css';
import { db } from './firebase';
import logo from './logo.svg';
import sendIcon from './send.svg';

function App() {
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>We busy forging and sharpening our swords...</p>
      </header>
      <SubscribeForm /> 
    </div>
  );

  function SubscribeForm() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [sent, setSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const emailsRef = db.collection("emails");

    const onSignUpClicked = async (e) => {
      e.preventDefault()

      if (validate()) {
        try {
          setLoading(true)
          const snapshot = await emailsRef.where("email", "==", `${email}`).get();
          if (!snapshot.empty) {
            setSent(true);
            //setError("Looks like you already subscribed!")
          } else {
  
            await emailsRef.doc().set({
              "email": `${ email }`,
              "subscribed": true,
            }, { merge: true });
  
            setSent(true);
          }
        } catch (e) {
          console.log("there was an error")
          console.log(e);
          setError(e.message);
          setSent(false);
        } finally {
          console.log('finally');
          setLoading(false)
        }
      }
    }
    
    const validate = () => {
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

      if (!pattern.test(email)) {
        setError("Please enter valid email address.");
        return false;
      }
      else {
        setError('')
        return true;
      }
    }

    if (loading) {
      return (<section>
        <div className="col height-150">
          <h3>Talking to the king...</h3>
        </div>
      </section>)
    }

    if (sent) {
      return (<section>
        <div className="col height-150">
          <h3>Thanks for subscribing</h3>
          <p>We will let you know when we ready!</p>
        </div>
      </section>) 
    }
      
    
    
    return (<section>
      <h1> Subscribe </h1>
      <div className="input-line">
        <form onSubmit={(e) => onSignUpClicked(e)}>
          <input
            name="email"
            type="text"
            value={email}
            placeholder={"Enter your email..."}
            onChange={e => { setEmail(e.target.value); setError('') }}
          />
                  
          <button>
            <img className="send-icon" src={sendIcon} alt="send" />
          </button>

        </form>
        <div className="error-container">
          <h5 className="error-description">{error}</h5>
        </div>
      </div>
    </section>);
  }
}

export default App;
