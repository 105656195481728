import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBZg2ljXxd8MoGQMO3BRdEijMVjEhLWjxg",
  authDomain: "doodle-clan.firebaseapp.com",
  projectId: "doodle-clan-www",
  storageBucket: "doodle-clan-www.appspot.com",
  messagingSenderId: "978189877896",
  appId: "1:978189877896:web:b248e78c61eb69207d553d"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore()

export {
  db
};
